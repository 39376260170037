#news {
  .wrapper {
    margin: 0 auto;
    max-width: 1280px;
    padding: 80px;
    @media #{$minM} {
      display: flex;
    }
    @media #{$maxM} {
      padding: 40px 24px;
    }
  }
  .title {
    @include serif-font;

    @media #{$minM} {
      flex: 0 0 240px;
      padding-right: 40px;
      position: relative;
      span {
        left: 0;
        position: sticky;
        top: 0;
      }
    }
    @media #{$minL} {
      flex: 0 0 400px;
    }
    h2 {
      color: $green;
      font-size: 60px;
      font-weight: 500;
      @media #{$maxL} {
        font-size: 56px;
      }
      @media #{$maxS} {
        font-size: 50px;
      }
    }
    h1 {
      font-size: 24px;
      font-weight: 600;
      @media #{$maxS} {
        font-size: 18px;
      }
    }
  }
  .body {
    flex: 1;
    min-width: 0;
    padding-top: 12px;
    @media #{$maxM} {
      padding-top: 80px;
    }
    article + article {
      border-top: 1px solid #ccc;
      margin-top: 80px;
      padding-top: 80px;
    }
    h3 {
      @include serif-font;

      font-size: 30px;
      line-height: 1.666;
      @media #{$maxS} {
        font-size: 26px;
      }
      & + p {
        line-height: 2.125;
        margin-top: 56px;
        @media #{$maxS} {
          margin-top: 40px;
        }
      }
    }
    figure {
      margin-top: 40px;
      video {
        max-width: 100%;
      }
      figcaption {
        font-size: 11px;
        padding-top: 8px;
        text-align: right;
      }
    }
  }
}
