:root,
body {
  color: #000;
  font-family: "Noto Sans JP", "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-size: 16px;
  -moz-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal;
  line-height: 1.4;
  text-size-adjust: 100%;
  @media #{$maxXS} {
    font-size: 15px;
  }
}
@mixin serif-font {
  font-family: "Noto Serif JP", serif;
}
