@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.footer {
  color: #fff;
  overflow: hidden;
  width: 100%;
  a {
    color: #fff;
  }
  &-recruit {
    position: relative;
    text-align: center;
    h2 {
      @include serif-font;

      font-size: 40px;
      line-height: 1.6;
      margin-top: 48px;
      @media #{$maxS} {
        font-size: 20px;
        letter-spacing: 0.2em;
        margin-top: 24px;
        padding-left: 0.2em;
      }
      @media #{$maxXS} {
        letter-spacing: 0.1em;
        padding-left: 0.1em;
      }
    }
  }
  &Recruit {
    &-logo img {
      @media #{$maxS} {
        width: 170px;
      }
    }
    &-bg img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
    &-inner {
      background: rgba(#000, 0.6);
      padding: 126px 40px;
      position: relative;
      z-index: 2;
      @media #{$maxS} {
        padding: 56px 32px;
      }
      @media #{$maxXS} {
        padding: 56px 24px;
      }
    }
    &-button {
      display: flex;
      justify-content: center;
      margin-top: 48px;
      @media #{$maxS} {
        margin-top: 32px;
      }
      a {
        align-items: center;
        border: 1px solid #fff;
        border-radius: 100px;
        color: #fff;
        display: flex;
        font-size: 20px;
        margin-left: 20px;
        padding: 18px 48px 22px;

        @media #{$maxS} {
          font-size: 13px;
          margin-left: 0;
          padding: 14px 32px 16px;
        }
        @media (hover: hover) {
          transition: 0.3s;
          &:hover {
            border-color: $green;
            color: $green;
          }
        }
        &:active {
          border-color: $green;
          color: $green;
        }
        i {
          font-size: 44px;
          line-height: 20px;
          margin-left: 16px;
          @media #{$maxS} {
            font-size: 32px;
          }
        }
      }
    }
    &-ticker {
      bottom: 12px;
      color: #fff;
      display: flex;
      font-size: 98px;
      font-weight: 500;
      left: 0;
      opacity: 0.1;
      position: absolute;
      white-space: nowrap;
      width: 100%;
      z-index: 1;
      @include serif-font;
      @media #{$maxS} {
        font-size: 64px;
      }
      p {
        animation: ticker 280s linear infinite;
        backface-visibility: hidden;
        line-height: 1;
        will-change: transform;
      }
    }
  }
  &-links {
    background: $green;
    padding: 40px;
    @include serif-font;
    @media #{$maxS} {
      padding: 40px 0;
    }
  }
  &Links {
    &-list {
      display: flex;
      margin: 0 auto;
      max-width: 1120px;
      @media #{$maxS} {
        max-width: 480px;
      }
      a {
        align-items: flex-end;
        display: flex;
        flex: 1;
        font-size: 24px;
        padding: 16px 120px 32px 0;
        @media #{$maxL} {
          font-size: 20px;
          padding: 0 40px 16px 0;
        }
        @media #{$maxS} {
          font-size: 16px;
          padding: 0 40px 16px;
        }
        @media #{$maxXS} {
          font-size: 13px;
          padding: 0 24px 16px;
        }
        @media (hover: hover) {
          transition: 0.3s;
          &:hover {
            color: #223b22;
          }
        }
        &:active {
          color: #223b22;
        }
        & + a {
          border-left: 1px solid #fff;
          padding: 16px 0 32px 80px;
          @media #{$maxL} {
            padding: 0 0 16px 40px;
          }
          @media #{$maxS} {
            padding: 0 40px 16px;
          }
          @media #{$maxXS} {
            padding: 0 24px 16px;
          }
        }
        span {
          flex: 1;
          @media #{$maxS} {
            span {
              display: flex;
            }
          }
        }
        b {
          display: block;
          font-size: 52px;
          font-weight: 500;
          padding-bottom: 8px;
          @media #{$maxL} {
            font-size: 44px;
          }
          @media #{$maxS} {
            font-size: 30px;
          }
          @media #{$maxXS} {
            font-size: 24px;
          }
        }
        i {
          font-size: 140px;
          line-height: 48px;
          @media #{$maxL} {
            font-size: 88px;
            line-height: 32px;
          }
          @media #{$maxS} {
            font-size: 48px;
            line-height: 15px;
            margin-left: 16px;
          }
        }
      }
    }
  }
  &-copy {
    background: #223b22;
    padding: 34px;
    text-align: center;
    @media #{$maxM} {
      padding-bottom: 120px;
    }
    p {
      @include serif-font;

      font-size: 12px;
      letter-spacing: 0.14em;
      margin-top: 32px;
      padding-left: 0.14em;
      @media #{$maxXS} {
        font-size: 10px;
      }
    }
  }
  &Copy-sns {
    display: flex;
    font-size: 38px;
    justify-content: center;
    @media #{$maxXS} {
      font-size: 24px;
    }
    li {
      padding: 0 24px;

      @media #{$maxXS} {
        padding: 0 8px;
        a {
          padding: 16px;
        }
      }
      a {
        @media (hover: hover) {
          transition: 0.3s;
          &:hover {
            color: $green;
          }
        }
        &:active {
          color: $green;
        }
      }
    }
  }
}
