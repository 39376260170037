@import "~swiper/css";
@import "~swiper/css/pagination";
#index {
  .header {
    @media #{$minM} {
      background: none;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      nav a {
        color: #fff;
      }
      &-logo svg path {
        fill: #fff;
      }
    }
  }
  .hero {
    color: #fff;
    overflow: hidden;
    position: relative;
    &-bg {
      @media #{$maxM} {
        max-height: 667px;
        &::before {
          content: "";
          display: block;
          padding-top: calc(177.86% - 75px);
        }
      }
    }
    &Bg {
      &-gradation {
        background: linear-gradient(rgba(#000, 0.6), rgba(#000, 0));
        height: 70%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        @media #{$maxM} {
          background: linear-gradient(
            rgba(#000, 0) 0%,
            rgba(#000, 0.2) 40%,
            rgba(#000, 0.2) 60%,
            rgba(#000, 0) 100%
          );
          bottom: 20%;
          height: 60%;
          top: 20%;
        }
      }
      &-overlay {
        background: radial-gradient(#000 50%, transparent 50%);
        background-size: 4px 4px;
        bottom: 0;
        height: 100%;
        left: 0;
        opacity: 0.06;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        @media #{$maxM} {
          background-size: 2px 2px;
        }
      }
      &-video {
        display: block;
        width: 100%;
        z-index: 0;
        @media #{$maxM} {
          height: 100%;
          left: 0;
          object-fit: cover;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
    &-body {
      align-items: center;
      bottom: 0;
      display: flex;
      left: 0;
      margin: 0 auto;
      max-width: 1120px;
      padding: 0 80px;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 2;
      @media #{$maxM} {
        max-width: 480px;
        padding: 0 12px;
      }
      h2 {
        @include serif-font;

        font-size: 80px;
        font-weight: 500;
        @media #{$maxL} {
          font-size: 64px;
        }
        @media #{$minL} {
          position: absolute;
        }
        @media #{$maxM} {
          font-size: 21px;
          text-align: center;
          img {
            max-width: 80%;
          }
          span {
            display: block;
            margin-top: 16px;
            padding-left: 1em;
          }
        }
      }
    }
    &-scroll {
      align-items: center;
      bottom: 40px;
      color: #fff;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.16em;
      position: absolute;
      right: calc(100% - 80px);
      transform: rotate(90deg);
      transform-origin: right center;
      z-index: 2;
      @include serif-font;
      @media #{$maxL} {
        right: calc(100% - 40px);
      }

      @keyframes scroll-sign {
        0% {
          left: 0;
        }
        100% {
          left: calc(100% - 12px);
        }
      }
      span {
        display: block;
        margin-left: 8px;
        position: relative;
        &::after {
          background: #fff;
          content: "";
          display: block;
          height: 1px;
          width: 80px;
        }
        &::before {
          animation: scroll-sign 2s linear infinite;
          background: $green;
          border-radius: 100%;
          content: "";
          display: block;
          height: 12px;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
        }
      }
    }
  }
  .about {
    overflow: hidden;
    padding: 120px 80px;
    position: relative;
    @media #{$maxL} {
      padding: 120px 56px;
    }
    @media #{$maxM} {
      padding: 72px 32px;
    }
    &-bg {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
      &::after {
        background: rgba(#ecf2ec, 0.77);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
    &-body {
      @include serif-font;

      font-size: 20px;
      line-height: 2.5;
      margin: 0 auto;
      max-width: 940px;
      position: relative;
      z-index: 1;
      @media #{$maxS} {
        font-size: 16px;
        line-height: 2.07;
        margin: 0 auto;
        max-width: 400px;
      }
      @media #{$maxXS} {
        font-size: 14px;
      }
      h2 {
        font-size: 42px;
        font-weight: 400;
        letter-spacing: 0.04em;
        line-height: 1.7;
        margin-bottom: 40px;
        @media #{$maxL} {
          font-size: 34px;
        }
        @media #{$maxS} {
          font-size: 30px;
          letter-spacing: normal;
          line-height: 2.07;
          margin: 0 -1em 24px 0;
        }
        @media #{$maxXS} {
          font-size: 24px;
        }
      }
    }
  }

  @mixin section-title {
    align-items: center;
    display: flex;
    line-height: 1;
    @include serif-font;
    b {
      color: $green;
      font-size: 60px;
      font-weight: 500;
      @media #{$maxM} {
        font-size: 40px;
      }
      @media #{$maxXS} {
        font-size: 35px;
      }
    }
    h2 {
      font-size: 24px;
      font-weight: 600;
      margin-left: 1em;
      padding-top: 0.33em;
      @media #{$maxM} {
        font-size: 20px;
      }
      @media #{$maxXS} {
        font-size: 17px;
      }
    }
  }
  .news {
    background: #ecf2ec;
    overflow: hidden;
    padding: 64px 80px;

    @media #{$maxL} {
      padding: 64px 56px;
    }
    @media #{$maxM} {
      padding: 24px 32px;
    }
    &-title {
      @include section-title;

      margin: 0 auto;
      max-width: 1120px;
      .swiper-button {
        display: flex;
        margin-left: auto;
        a {
          align-items: center;
          background: $green;
          color: #fff;
          display: flex;
          height: 50px;
          justify-content: center;
          width: 50px;
          & + a {
            margin-left: 16px;
            @media #{$maxM} {
              margin-left: 8px;
            }
          }
          @media #{$maxM} {
            height: 25px;
            width: 25px;
          }
          @media (hover: hover) {
            transition: 0.3s;
            &:hover {
              background: #223b22;
            }
          }
          &:active {
            background: #223b22;
          }
        }
        i {
          display: block;
          font-size: 20px;
          @media #{$maxM} {
            font-size: 10px;
          }
        }
        &-prev {
          i {
            transform: rotate(180deg);
          }
        }
      }
    }
    &-body {
      margin: 24px -80px;
      a,
      img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: 0.4s;
        width: 100%;
      }
      img {
        object-fit: cover;
      }
      a {
        align-items: center;
        background: rgba(#333, 0.6);
        color: #fff;
        display: flex;
        flex-direction: column;
        font-size: 22px;
        justify-content: center;
        padding: 80px 40px;
        @media #{$maxM} {
          font-size: 18px;
          padding: 50px 20px;
        }
        @media #{$maxS} {
          font-size: 14px;
        }
      }
      li {
        border-radius: 100%;
        height: 380px;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 380px;
        @media #{$maxM} {
          height: 320px;
          width: 320px;
        }
        @media #{$maxS} {
          height: 250px;
          width: 250px;
        }
        @media (hover: hover) {
          &:hover {
            img {
              transform: scale(1.06);
            }
            a {
              background-color: rgba(#333, 0.8);
            }
          }
        }
        &:active {
          img {
            transform: scale(1.06);
          }
          a {
            background-color: rgba(#333, 0.8);
          }
        }
      }
      small,
      span {
        font-size: 14px;
        @media #{$maxS} {
          font-size: 12px;
        }
      }
      span {
        align-items: flex-start;
        display: flex;
        i {
          font-size: 38px;
          line-height: 18px;
          margin-left: 6px;
        }
      }
      h3 {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        line-height: 1.6;
        @media #{$maxM} {
          font-weight: 600;
        }
      }
    }
  }
  .column {
    background: linear-gradient(
      180deg,
      #fff 0%,
      #fff 50%,
      #ecf2ec 50%,
      #ecf2ec 100%
    );
    overflow: hidden;
    padding: 120px 80px;
    position: relative;
    @media #{$maxL} {
      padding: 120px 56px;
    }
    @media #{$maxM} {
      padding: 64px 32px;
    }
    @media #{$maxS} {
      background: #fff;
      padding: 0 0 40px;
    }
    & > div {
      @media #{$minS} {
        display: flex;
        margin: 0 auto;
        max-width: 1120px;
      }
    }
    &-body {
      width: 52%;
      @media #{$maxL} {
        max-width: 560px;
        width: 70%;
      }
      @media #{$maxS} {
        font-size: 16px;
        line-height: 2.07;
        max-width: 100%;
        width: 100%;
      }
      @media #{$maxXS} {
        font-size: 14px;
      }
    }
    &Body {
      &-title {
        @include section-title;
        @media #{$maxS} {
          margin: 0 auto;
          max-width: 464px;
          padding: 64px 32px 0;
        }
      }
      &-lede {
        @include serif-font;

        font-size: 18px;
        line-height: 2.33;
        margin-top: 40px;
        @media #{$maxM} {
          font-size: 16px;
          margin-top: 24px;
        }
        @media #{$maxS} {
          margin-left: auto;
          margin-right: auto;
          max-width: 464px;
          padding: 0 32px;
        }
        @media #{$maxXS} {
          font-size: 14px;
          line-height: 1.78;
        }
      }
      &-links {
        margin-top: 80px;
        @media #{$maxS} {
          background: #ecf2ec;
          margin: 32px 0 0;
          padding: 24px 24px 100px;
          & > div {
            margin: 0 auto;
            max-width: 400px;
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -8px -16px;
        }
        li {
          padding: 0 8px 16px;
          width: 50%;
        }
        a {
          background: #fff;
          color: $green;
          display: block;
          font-size: 22px;
          font-weight: 700;
          padding: 32px 16px 24px;
          text-align: center;
          @media #{$maxL} {
            font-size: 18px;
          }
          @media #{$maxS} {
            font-size: 14px;
            padding: 14px 4px;
          }
          @media (hover: hover) {
            transition: 0.3s;
            &:hover {
              color: #223b22;
            }
          }
          &:active {
            color: #223b22;
          }
        }
        i {
          display: block;
          font-size: 60px;
          line-height: 15px;
          margin-top: 16px;
          @media #{$maxS} {
            font-size: 34px;
            margin: 0;
          }
        }
      }
    }
    &-image {
      flex: 1;
      margin: 0 -160px 0 100px;
      position: relative;
      @media #{$maxL} {
        margin-left: 40px;
      }
      @media #{$maxS} {
        bottom: 0;
        margin: -76px auto 0;
        max-width: 448px;
        padding: 0 24px;
        position: relative;
        picture {
          display: block;
          width: calc(50vw + 224px);
        }
      }
      img {
        height: 100%;
        @media #{$minS} {
          left: 0;
          object-fit: cover;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}
