@mixin placeholder {
  &::input-placeholder {
    @content;
  }
  &:placeholder {
    opacity: 1;
    @content;
  }
  &::placeholder {
    opacity: 1;
    @content;
  }
  &:input-placeholder {
    @content;
  }
  &:placeholder-shown {
    @content;
  }
}
#contact {
  .wrapper {
    padding: 80px 0;
    @media #{$maxM} {
      padding: 40px 0;
    }
  }
  .title {
    @include serif-font;

    margin: 0 auto;
    max-width: 1280px;
    padding: 0 80px;
    @media #{$maxM} {
      padding: 0 24px;
    }
    h2 {
      color: $green;
      font-size: 60px;
      font-weight: 500;
      @media #{$maxL} {
        font-size: 56px;
      }
      @media #{$maxS} {
        font-size: 50px;
      }
    }
    h1 {
      font-size: 24px;
      font-weight: 600;
      @media #{$maxS} {
        font-size: 18px;
      }
    }
  }
  .cover {
    height: 310px;
    margin: 80px 0 0;
    @media #{$maxXS} {
      margin: 40px 0 0;
    }
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  .body {
    line-height: 2.125;
    margin: 0 auto;
    max-width: 880px;
    padding: 80px 80px 0;
    @media #{$maxM} {
      max-width: 768px;
      padding: 80px 24px 0;
    }
    @media #{$maxXS} {
      padding: 40px 24px 0;
    }
  }
  .contact {
    &-tel {
      align-items: center;
      background: #f8f8f8;
      border-radius: 10px;
      display: flex;
      font-size: 18px;
      line-height: 1;
      margin-top: 40px;
      padding: 56px 80px;

      @media #{$maxM} {
        display: block;
        padding: 24px 32px;
        text-align: center;
      }
      div {
        flex: 1;
        padding-right: 40px;
        @media #{$maxM} {
          padding: 0 0 8px;
        }
      }
      span {
        min-width: 0;
      }
      b {
        display: block;
        flex: 1;
        font-size: 44px;
        font-weight: 500;
        @media #{$maxM} {
          font-size: 40px;
        }
      }
      small {
        display: block;
        font-size: 14px;
        margin-top: 8px;
        text-align: right;
        @media #{$maxM} {
          margin-top: 12px;
          text-align: center;
        }
      }
      & + p {
        margin-top: 40px;
        b {
          color: red;
        }
      }
    }
  }
  form {
    margin-top: 80px;
    dl {
      font-size: 16px;
      & > div {
        display: flex;
        margin: 2em 0;
        @media #{$maxM} {
          display: block;
        }
        span {
          flex: 1;
          @media #{$maxM} {
            display: block;
          }
          & + span {
            margin-left: 2em;
            @media #{$maxM} {
              margin: 2em 0 0;
            }
          }
        }
      }
      dt {
        b {
          color: red;
        }
      }
      dd {
        display: flex;
        margin: 8px 0 0;
        position: relative;
        label {
          align-items: center;
          display: flex;
          & + label {
            margin-left: 2em;
          }
        }
        & + dt {
          margin-top: 2em;
        }
      }
    }
    input[type="radio"] {
      margin: 0;
      opacity: 0;
      width: 0;
      & + span {
        align-items: center;
        display: flex;
        position: relative;
        &::before {
          background: #f8f8f8;
          border: 1px solid #dedede;
          border-radius: 100%;
          content: "";
          display: block;
          height: 30px;
          margin-right: 0.8em;
          width: 30px;
        }
        &::after {
          border-radius: 100%;
          content: "";
          display: block;
          height: 15px;
          left: 8px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 15px;
        }
      }
      &:focus {
        outline: 0 none;
        & + span::before {
          border-color: #999;
        }
      }
      &:checked + span {
        &::before {
          border-color: #666;
        }
        &::after {
          background: #666;
        }
      }
    }

    //mwformの設定
    .horizontal-item {
      label {
        display: flex;
      }
      & + .horizontal-item {
        margin-left: 2em;
      }
    }
    span.error {
      font-size: 12px;
      font-weight: bold;
      left: 0;
      line-height: 1;
      margin-top: 2px;
      position: absolute;
      top: 100%;
      & + input,
      & + textarea {
        background-color: #f7efef;
        border-color: #b70000;
      }
    }
    input[type="text"],
    input[type="email"],
    textarea {
      background: #f8f8f8;
      border: 1px solid #dedede;
      border-radius: 10px;
      display: block;
      padding: 20px 16px;
      width: 100%;
      &:active,
      &:focus {
        border-color: #999;
        outline: 0 none;
      }
      @include placeholder {
        color: #aaa;
      }
    }
    .button {
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
    button {
      align-items: center;
      background: $green;
      border: 1px solid $green;
      border-radius: 100px;
      color: #fff;
      display: flex;
      font-size: 20px;
      line-height: 1.4;
      padding: 18px 48px 22px;

      @media #{$maxS} {
        font-size: 16px;
        padding: 14px 32px 16px;
      }
      &:active,
      &:focus {
        outline: 0 none;
      }
      @media (hover: hover) {
        transition: 0.3s;
        &:hover {
          background: #223b22;
        }
      }
      &:active {
        background: #223b22;
      }
      i {
        font-size: 44px;
        line-height: 20px;
        margin-left: 16px;
        @media #{$maxS} {
          font-size: 32px;
        }
      }
    }
  }
  .mw_wp_form_send_error {
    color: #b70000;
    margin: 120px 0 80px;
  }
  .mw_wp_form_complete {
    margin: 120px 0 80px;
  }
}
