#company {
  .main,
  .access {
    padding: 80px 0;
    @media #{$maxM} {
      padding: 40px 0;
    }
  }
  .title {
    @include serif-font;

    margin: 0 auto;
    max-width: 1280px;
    padding: 0 80px;
    @media #{$maxM} {
      padding: 0 24px;
    }
    h2 {
      color: $green;
      font-size: 60px;
      font-weight: 500;
      @media #{$maxL} {
        font-size: 56px;
      }
      @media #{$maxS} {
        font-size: 50px;
      }
    }
    h1 {
      font-size: 24px;
      font-weight: 600;
      @media #{$maxS} {
        font-size: 18px;
      }
    }
  }
  .cover {
    height: 310px;
    margin: 80px 0 0;
    @media #{$maxXS} {
      margin: 40px 0 0;
    }
    img,
    iframe {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  .body {
    margin: 0 auto;
    max-width: 1280px;
    padding: 80px 80px 0;
    @media #{$maxM} {
      padding: 80px 24px 0;
    }
    @media #{$maxXS} {
      padding: 40px 24px 0;
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      font-size: 18px;
      line-height: 1.666;
      margin: -1.5em auto 0;
      max-width: 840px;
      @media #{$maxM} {
        font-size: 14px;
      }
      a {
        color: $green;
        display: inline-block;
        text-decoration: underline;
        @media (hover: hover) {
          &:hover {
            text-decoration: none;
          }
        }
        &:active {
          text-decoration: none;
        }
      }
      dt,
      dd {
        padding: 1.5em 0;
      }
      dt {
        border-bottom: 1px solid #000;
        width: 272px;
        @media #{$maxM} {
          width: 11em;
        }
        @media #{$maxXS} {
          border: 0;
          font-weight: 600;
          padding-bottom: 0.5em;
          width: 100%;
        }
        &:nth-last-child(2) {
          border: 0;
        }
      }
      dd {
        border-bottom: 1px solid #ccc;
        margin: 0;
        padding-left: 2em;
        width: calc(100% - 272px);
        @media #{$maxM} {
          width: calc(100% - 11em);
        }
        @media #{$maxXS} {
          color: #666;
          padding-top: 0;
          width: 100%;
        }
        &:last-child {
          border: 0;
        }
      }
    }
  }
  .access {
    background: $green-bg;
    .cover {
      margin-top: 40px;
    }
    .body {
      padding-top: 40px;
    }
  }
}
