.header {
  align-items: center;
  background: #fff;
  display: flex;
  font-size: 16px;
  padding: 40px 80px;
  width: 100%;
  @media #{$maxL} {
    padding: 40px 24px;
  }
  @media #{$maxM} {
    background: rgba($darkgreen, 0.9);
    bottom: 0;
    left: 0;
    padding: 20px 24px;
    position: fixed;
    right: 0;
    top: auto;
    z-index: 10;
  }
  &-logo {
    a {
      display: block;
      width: 200px;
      @media #{$maxM} {
        width: 120px;
      }
    }
    svg path {
      fill: #000;
      @media #{$maxM} {
        fill: #fff;
      }
    }
  }
  &-nav {
    align-items: center;
    display: flex;
    letter-spacing: 0.04em;
    margin-left: auto;
    ul {
      display: flex;
      position: relative;
      @media #{$maxM} {
        background: rgba($darkgreen, 0);
        flex-direction: column;
        height: calc(100vh - 75px);
        left: 0;
        opacity: 0;
        overflow-y: auto;
        padding: 24px;
        position: fixed;
        text-align: right;
        top: 100%;
        transition: 0.1s;
        width: 100%;
        li:first-child {
          margin-top: auto;
        }
      }
      a {
        color: #000;
        padding: 8px 16px 10px;
        @media #{$maxL} {
          padding: 8px 12px 10px;
        }
        @media #{$maxM} {
          color: #fff;
          display: inline-block;
          margin-left: auto;
        }
      }
    }
  }
  &Nav-button {
    background: $green;
    border-radius: 100px;
    color: #fff;
    display: block;
    line-height: 1;
    margin-left: 20px;
    padding: 14px 32px 16px;
    @media #{$maxM} {
      font-size: 13px;
      margin-right: 12px;
      padding: 10px 18px 12px;
    }
    @media (hover: hover) {
      transition: 0.3s;
      &:hover {
        background: #223b22;
      }
    }
    &:active {
      background: #223b22;
    }
  }
  &-toggle {
    display: flex;
    flex: 0 0 26px;
    flex-direction: column;
    height: 22px;
    justify-content: space-between;
    order: 1;
    transition: 0.2s 0.1s;
    i {
      background: #fff;
      display: block;
      height: 2px;
      transition: 0.2s;
      width: 100%;
      &:first-child,
      &:last-child {
        background: #fff;
        content: "";
        display: block;
        height: 2px;
        width: 100%;
      }
    }
  }
}
#toggleNav:checked + .header {
  .header-toggle {
    transform: rotate(45deg);
    i {
      &:nth-child(2) {
        opacity: 0;
      }
      &:first-child {
        transform: translateY(10px);
      }
      &:last-child {
        transform: translateY(-10px) rotate(90deg);
      }
    }
  }
  .header-nav ul {
    background: rgba($darkgreen, 0.9);
    opacity: 1;
    top: 0;
  }
}
