@charset "utf-8";
@import "~normalize.scss/normalize";
@import "~swiper/css";
@import "~swiper/css/pagination";

@import "config/config";
@import "components/header";
@import "pages/index";
@import "pages/news";
@import "pages/column";
@import "pages/works";
@import "pages/company";
@import "pages/contact";
@import "pages/recruit";
@import "components/footer";
