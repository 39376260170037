$minL: "all and (min-width: 1200px)";
$maxL: "all and (max-width: 1199px)";
$minM: "all and (min-width: 920px)";
$maxM: "all and (max-width: 919px)";
$minS: "all and (min-width: 768px)";
$maxS: "all and (max-width: 767px)";
$minXS: "all and (min-width: 480px)";
$maxXS: "all and (max-width: 479px)";

// $minS: "all and (min-width: 640px)";
// $maxS: "all and (max-width: 639px)";

@media #{$maxM} {
  .hideMaxM {
    display: none !important;
  }
}
@media #{$minM} {
  .hideMinM {
    display: none !important;
  }
}
@media #{$maxS} {
  .hideMaxS {
    display: none !important;
  }
}
@media #{$minS} {
  .hideMinS {
    display: none !important;
  }
}
@media #{$maxXS} {
  .hideMaxXS {
    display: none !important;
  }
}
@media #{$minXS} {
  .hideMinXS {
    display: none !important;
  }
}
