/* add reset */

*,
*:before,
*:after {
  box-sizing: border-box;
}

ul,
ol,
dl {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  cursor: pointer;
  text-decoration: none;
}
a,
a img {
  outline: none;
}
p {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}
figure {
  margin: 0;
}

/* add reset */
