#column {
  .wrapper {
    margin: 0 auto;
    max-width: 1280px;
    padding: 80px;
    @media #{$minM} {
      display: flex;
    }
    @media #{$maxM} {
      padding: 40px 24px;
    }
  }
  .title {
    @include serif-font;

    @media #{$minM} {
      flex: 0 0 240px;
      padding-right: 40px;
      position: relative;
      span {
        left: 0;
        position: sticky;
        top: 0;
      }
    }
    @media #{$minL} {
      flex: 0 0 400px;
    }
    h2 {
      color: $green;
      font-size: 60px;
      font-weight: 500;
      @media #{$maxL} {
        font-size: 56px;
      }
      @media #{$maxS} {
        font-size: 50px;
      }
    }
    h1 {
      font-size: 24px;
      font-weight: 600;
      @media #{$maxS} {
        font-size: 18px;
      }
    }
  }
  .body {
    flex: 1;
    min-width: 0;
    padding-top: 12px;
    @media #{$maxM} {
      padding-top: 80px;
    }
    article + article {
      border-top: 1px solid #ccc;
      margin-top: 80px;
      padding-top: 80px;
    }
    h3 {
      @include serif-font;

      font-size: 30px;
      line-height: 1.666;
      @media #{$maxS} {
        font-size: 26px;
      }
      small {
        font-size: 20px;
        padding-left: 1em;
      }
      & + * {
        margin-top: 56px;
        @media #{$maxS} {
          margin-top: 40px;
        }
      }
    }
    p {
      line-height: 2.125;
    }
    figure {
      picture {
        position: relative;
        &::before {
          content: "";
          display: block;
          padding-top: 75%;
        }
      }
      img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
      figcaption {
        font-size: 11px;
        text-align: right;
      }
    }
  }
  .column {
    &-gallery {
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0 -24px;
      li {
        margin-bottom: 24px;
        width: 50%;
      }
      picture {
        display: block;
      }
    }
    &-list {
      li {
        @media #{$minM} {
          display: flex;
        }
        & + li {
          margin-top: 48px;
        }
        & > div {
          flex: 1;
          padding-right: 32px;
          @media #{$maxM} {
            padding: 0 0 32px;
          }
        }
        figure {
          @media #{$minM} {
            flex: 0 1 360px;
            max-width: 360px;
          }
        }
        picture {
          display: block;
        }
      }
      h4 {
        font-weight: bold;
        line-height: 2.125;
        padding-bottom: 8px;
      }
    }
  }
}
