@import "reset";
@import "icomoon";
@import "mediaquery";
@import "typography";
@import "colors";
:root,
body {
  min-width: 375px;
  width: 100%;
}
svg,
img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
}
a {
  &:active:focus,
  &:focus,
  &:active {
    opacity: 1;
    outline: 0 none;
  }
}
