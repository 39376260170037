$icomoon-font-family: "yasuda" !default;
$icomoon-font-path: "../fonts" !default;
$icon-chevron: "\e905";
$icon-arrow-wide: "\e904";
$icon-arrow: "\e900";
$icon-youtube: "\e901";
$icon-tiktok: "\e902";
$icon-instagram: "\e903";

@font-face {
  font-display: block;
  font-family: "#{$icomoon-font-family}";
  font-style: normal;
  font-weight: normal;
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?a3kg7w");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?a3kg7w#iefix")
      format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?a3kg7w")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?a3kg7w")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?a3kg7w##{$icomoon-font-family}")
      format("svg");
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}
.icon-chevron {
  &::before {
    content: $icon-chevron;
  }
}
.icon-arrow-wide {
  &::before {
    content: $icon-arrow-wide;
  }
}
.icon-arrow {
  &::before {
    content: $icon-arrow;
  }
}
.icon-youtube {
  &::before {
    content: $icon-youtube;
  }
}
.icon-tiktok {
  &::before {
    content: $icon-tiktok;
  }
}
.icon-instagram {
  &::before {
    content: $icon-instagram;
  }
}
