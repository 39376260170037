#recruit {
  main {
    overflow: hidden;
  }
  .hero {
    max-height: 695px;
    overflow: hidden;
    position: relative;
    &::before {
      content: "";
      display: block;
      padding-top: 48.2638%;
      @media #{$maxM} {
        padding-top: 216.41%;
      }
    }
    &-bg {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      @media #{$maxM} {
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
    &-inner {
      display: flex;
      left: 50%;
      margin: 0 auto;
      max-width: 1440px;
      padding: 40px 40px 220px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: 1;
      @media #{$maxM} {
        padding: 0 40px;
      }
      @media #{$maxS} {
        padding: 0 24px;
      }
      img {
        margin: 0;
      }
    }
    &-title {
      @include serif-font;

      color: #fff;
      font-size: 38px;
      font-weight: 500;
      position: relative;
      @media #{$maxM} {
        font-size: 32px;
        max-width: 640px;
      }
      @media #{$maxS} {
        font-size: 24px;
      }
      h1 {
        bottom: -8px;
        position: absolute;
        right: -40px;
        @media #{$maxM} {
          bottom: -24px;
        }
        @media #{$maxS} {
          bottom: -32px;
          right: -12px;
        }
      }
    }
    &-circle {
      align-items: center;
      background: $green;
      border-radius: 100%;
      bottom: 40px;
      box-shadow: 0 3px 16px rgba(#000, 0.16);
      color: #fff;
      display: flex;
      font-size: 18px;
      height: 160px;
      justify-content: center;
      position: fixed;
      right: 40px;
      text-align: center;
      width: 160px;
      z-index: 10;
      @media #{$maxM} {
        bottom: 96px;
        font-size: 14px;
        height: 100px;
        right: 12px;
        width: 100px;
      }
      @media (hover: hover) {
        transition: 0.3s;
        &:hover {
          background: #223b22;
          transform: scale(1.01);
        }
      }
      &:active {
        background: #223b22;
        transform: scale(1.01);
      }
      span {
        i {
          display: block;
          font-size: 42px;
          line-height: 32px;
          margin-top: 4px;
          @media #{$maxM} {
            font-size: 32px;
            line-height: 16px;
          }
        }
      }
    }
  }
  .about {
    display: flex;
    padding: 120px 80px 0;
    @media #{$minS} {
      margin: 0 auto;
      max-width: 1280px;
    }
    @media #{$maxM} {
      flex-wrap: wrap;
      padding: 40px 24px 0;
    }
    &-side {
      margin: 0 100px 0 -80px;
      width: 380px;
      @media #{$maxM} {
        align-items: flex-end;
        display: flex;
        margin: 0 0 80px;
        width: 100%;
      }
      picture {
        overflow: visible;
        @media #{$minM} {
          display: flex;
          justify-content: flex-end;
          & + picture {
            margin-top: 45px;
          }
        }
        @media #{$maxM} {
          flex: 1;
          &:first-child {
            position: relative;
            z-index: 1;
            img {
              margin-bottom: -40px;
            }
          }
          &:last-child {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      img {
        display: block;
        @media #{$minM} {
          max-width: unset;
          width: 460px;
        }
        @media #{$maxM} {
          max-width: 110%;
        }
      }
    }
    &-main {
      flex: 0 1 800px;
      min-width: 0;
      h2 {
        font-size: 48px;
        font-weight: 600;
        @include serif-font;
        @media #{$maxM} {
          font-size: 32px;
        }
      }
      p {
        padding-top: 48px;
        @media #{$minM} {
          font-size: 20px;
          line-height: 2.5;
        }
        @media #{$maxM} {
          line-height: 2.125;
        }
      }
    }
    &Main-img {
      @media #{$minM} {
        overflow: visible;
      }
      div {
        display: flex;
        @media #{$minM} {
          height: 408px;
          margin-top: 120px;
        }
        @media #{$maxM} {
          align-items: flex-start;
          margin: 80px 0 0;
        }
      }
      picture {
        @media #{$minM} {
          & + picture {
            margin-left: 40px;
          }
        }
        @media #{$maxM} {
          flex: 1;
          &:first-child {
            position: relative;
            z-index: 1;
            img {
              margin-top: -40px;
            }
          }
          &:last-child {
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      img {
        @media #{$minM} {
          height: 100%;
          max-width: initial;
          width: auto;
        }
        @media #{$maxM} {
          max-width: 110%;
        }
      }
    }
  }
  .message {
    display: flex;
    padding: 120px 80px;
    @media #{$minS} {
      margin: 0 auto;
      max-width: 1280px;
    }
    @media #{$maxM} {
      flex-wrap: wrap;
      padding: 80px 24px;
    }
    &-image {
      margin: 0 100px 0 -80px;
      width: 380px;
      @media #{$maxM} {
        margin: 0 0 40px -24px;
        width: calc(100% + 24px);
      }
      picture {
        display: flex;
        justify-content: flex-end;
        overflow: visible;
      }
      img {
        display: block;
        @media #{$minM} {
          max-width: unset;
          width: 460px;
        }
      }
    }
    &-main {
      h3 {
        color: $green;
        font-size: 20px;
        font-weight: 600;
        @media #{$maxM} {
          font-size: 18px;
        }
      }
      h2 img {
        margin: 16px auto 0 0;
        @media #{$maxM} {
          width: 320px;
        }
      }
      p {
        margin-top: 36px;
        @media #{$minM} {
          font-size: 18px;
          line-height: 2.111;
        }
        @media #{$maxM} {
          line-height: 2.125;
        }
      }
    }
  }
  .movie {
    overflow: hidden;
    padding: 0 80px;
    position: relative;
    @media #{$maxM} {
      padding: 0;
    }
    &-bg {
      left: 0;
      opacity: 0.3;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    &-video {
      aspect-ratio: 16 / 9;
      margin: 0 auto;
      max-width: 100%;
      position: relative;
      width: 1120px;
      z-index: 1;
      @media #{$maxM} {
        width: 480px;
      }
    }
    iframe {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
  .guideline {
    background: $green-bg;
    padding: 120px 80px;
    @media #{$maxM} {
      padding: 80px 24px;
    }
    &-main,
    &-contact {
      margin: 0 auto;
      max-width: 840px;
    }
    &-main {
      align-items: flex-start;
      display: flex;
      @media #{$maxM} {
        display: block;
      }
      h2 {
        @media #{$minM} {
          background: linear-gradient(
            to left,
            transparent 80%,
            rgba($green, 0.2) 80%
          );
          line-height: 1.2;
          padding: 8px 0 24px 14px;
          width: 170px;
        }
        span {
          font-size: 32px;
          @include serif-font;
          @media #{$minM} {
            display: block;
            font-size: 54px;
            width: 54px;
          }
          @media #{$maxM} {
            background: linear-gradient(transparent 60%, rgba($green, 0.2) 60%);
            display: inline-block;
          }
        }
      }
      dl {
        align-content: flex-start;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        @media #{$maxM} {
          margin-top: 12px;
        }
      }
      dt,
      dd {
        margin: 0;
        padding: 30px 0;
      }
      dt {
        border-bottom: 1px solid #000;
        font-size: 17px;
        width: 84px;
        &:nth-last-child(2) {
          border: 0;
        }
        @media #{$maxM} {
          border: 0;
          font-weight: 500;
          line-height: 1;
          margin-top: 30px;
          padding: 0;
          width: auto;
        }
      }
      dd {
        border-bottom: 1px solid #ccc;
        font-size: 14px;
        line-height: 1.86;
        padding-left: 1em;
        width: calc(100% - 84px);
        &:last-child {
          border: 0;
        }
        @media #{$maxM} {
          padding: 12px 0 30px 1em;
          width: 100%;
        }
      }
      b {
        padding-right: 1em;
      }
    }
    &-table {
      border-left: 3px solid rgba($green, 0.2);
      color: $darkgreen;
      font-size: 13px;
      margin: 1em 0 1em 0.5em;
      padding-left: 1em;
    }
    ol {
      counter-reset: item;
      list-style-type: none;
      li {
        padding-left: 2em;
        position: relative;
      }
      li::before {
        content: " ( " counter(item) " ) ";
        counter-increment: item;
        left: 0;
        position: absolute;
        top: 0;
      }
      ol {
        counter-reset: count;
        padding-left: 3px;
        li::before {
          content: "(" counter(count, lower-alpha) ")";
          counter-increment: count;
        }
        li {
          padding-left: 1.6em;
        }
      }
    }
    &-contact {
      background: #fff;
      border-radius: 10px;
      margin-top: 54px;
      padding: 38px 64px;
      white-space: nowrap;
      @media #{$maxM} {
        padding: 24px;
      }
      @media #{$maxS} {
        margin: 54px auto 0;
        max-width: 400px;
      }
      h3 span {
        background: linear-gradient(transparent 45%, rgba($green, 0.2) 45%);
        display: inline-block;
        font-size: 23px;
        font-weight: 600;
        padding: 0 8px 6px;
      }
    }
    &Contact {
      &-students {
        align-items: center;
        border-top: 1px solid #eee;
        display: flex;
        justify-content: center;
        margin-top: 32px;
        padding: 24px 0 8px;
        @media #{$maxS} {
          display: block;
        }
        p {
          padding-right: 16px;
          @media #{$maxS} {
            padding: 0 0 8px;
          }
        }
      }
      &-tel {
        span {
          display: block;
          margin-right: 2em;
          @media #{$maxS} {
            width: 100%;
          }
        }
        div {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
        }
        a {
          color: #000;
          display: block;
          font-size: 32px;
          font-weight: 500;
          padding-bottom: 0.1em;
          @media (hover: hover) {
            transition: 0.3s;
            &:hover {
              color: $green;
            }
          }
          &:active {
            color: $green;
          }
        }
      }
      &-inner {
        align-items: center;
        display: flex;
        margin-top: 26px;
        @media #{$maxS} {
          display: block;
        }
        span {
          color: #aaa;
          flex: 1;
          font-size: 16px;
          padding: 0 16px;
          text-align: center;
          @media #{$maxM} {
            flex: 0 1 auto;
          }
          @media #{$maxS} {
            display: block;
            font-size: 16px;
            padding: 16px 0;
            width: 100%;
          }
        }
        small {
          font-size: 16px;
        }
      }
      &Inner-button {
        align-items: center;
        background: $green;
        border: 1px solid $green;
        border-radius: 100px;
        color: #fff;
        display: inline-flex;
        font-size: 20px;
        justify-content: space-between;
        line-height: 1.4;
        padding: 18px 48px 22px;
        @media #{$maxS} {
          display: flex;
          padding: 14px 32px 16px;
        }
        @media (hover: hover) {
          transition: 0.3s;
          &:hover {
            background: #223b22;
          }
        }
        &:active {
          background: #223b22;
        }
        i {
          font-size: 44px;
          line-height: 20px;
          margin-left: 16px;
          @media #{$maxS} {
            font-size: 32px;
          }
        }
        &.jd {
          background: none;
          color: $green;
          height: 70px;
          padding-left: 20px;
          picture {
            padding-bottom: 4px;
          }
          @media (hover: hover) {
            &:hover {
              border-color: #223b22;
              color: #223b22;
            }
          }
          &:active {
            border-color: #223b22;
            color: #223b22;
          }
        }
      }
    }
  }
  .footer-recruit {
    display: none;
  }
}
